<template>
  <div>
    <Permission/>
    <Breadcrumb/>
    <div class="title">Legal Entity</div>
    <div class="subtitle">{{fieldsData ? fieldsData.corporationName : ''}}</div>
    <Tabs/>
    <section>
      <Form
        :fields="fieldsData"
        :canUpdate="canUpdate"
        @onSave="updateCorporation"
      >
        <template v-slot:bottom v-if="isLoading">
          <b-button type="is-blue" disabled>Save</b-button>
        </template>
      </Form>
    </section>
    <Flash title="Congrats" content="Corporation updated!" :show="showFlash" :hideFlash="hideFlash"></Flash>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import pageTitleMixin from '@utils/mixins/addy-plus-page-title-mixin.js'
import Tabs from './Tabs.vue'
import Form from './Form.vue'
import Flash from '@components/modal/slide-down-flash.vue'
import { fetchCorporationDetails, updateCorporation as updateCorp } from '@api/addy-plus/corporations'
import { nestedObjectToFormdata } from '@utils/common-methods/common'
import Permission from '@views/addy_plus/base/Permission.vue'
import Breadcrumb from '@components/breadcrumb/breadcrumb.vue'


export default {
  title: 'Corporation',
  mixins: [pageTitleMixin],
  components: {
    Permission,
    Tabs,
    Form,
    Flash,
    Breadcrumb,
  },
  data() {
    return {
      fieldsData: null,
      showFlash: false,
      timer: null,
      isLoading: false,
    }
  },
  computed: {
    ...mapState('addyPlusBase', ['permissions']),
    accountId() {
      return this.$route.params.accountId
    },
    corporationId() {
      return this.$route.params.corporationId
    },
    targetFieldElement() {
      const targetId = this.$route.query.target
      return targetId ? document.getElementById(targetId) : null
    },
    canUpdate() {
      return this.permissions.entity?.includes('update')
    },
  },
  mounted() {
    this.init()
    this.scrollToTarget()
  },
  watch: {
    corporationId() {
      this.init()
    },
  },
  methods: {
    init() {
      fetchCorporationDetails(this.corporationId).then((res) => {
        if (!res.success) return
        this.fieldsData = res.data
      })
    },
    updateCorporation(params) {
      this.isLoading = true

      if (!params.businessNumberBackup) delete params.businessNumberBackup

      const formData = nestedObjectToFormdata(params, 'corporation')

      formData.append('corporation[corp_country]', params.address.country) // required field on Corporation model
      formData.append('corporation[addy_plus_account_id]', this.accountId)

      updateCorp(this.corporationId, formData).then((res) => {
        if (!res.success) return
        this.showUpdateFlash()
      }).finally(() => {
        this.isLoading = false
      })
    },
    showUpdateFlash() {
      this.showFlash = true
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideFlash()
      }, 4000)
    },
    hideFlash() {
      this.showFlash = false
    },
    scrollToTarget() {
      if (!this.targetFieldElement) return

      this.targetFieldElement.scrollIntoView({ behavior: 'smooth', inline: 'start' })
      this.targetFieldElement.focus({ focusVisible: true, preventScroll: true })
    },
  },
}
</script>
