<template>
  <div :class="`flash-wrap is-flex is-justify-content-center ${show ? 'visible' : ''}`">
    <div class="is-clearfix flash is-relative">
      <img src="@assets/icons/close-primary.svg" alt="close" class="close is-clickable" @click="hideFlash">
      <div class="flash-title has-text-weight-semibold has-text-white">{{title}}</div>
      <div class="flash-content has-text-white">{{content}}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    hideFlash: {
      type: Function,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.flash-wrap {
  position: fixed;
  z-index: 111;
  width: 100vw;
  top: -200px;
  left: 0;
  transition: all 0.5s;
  &.visible {
    top: 13px;
  }
  .flash {
    width: 326px;
    min-height: 82px;
    background: #2D2926;
    border-radius: 12px;
    padding: 20px 16px 16px;
    .close {
      padding: 6px;
      position: absolute;
      top: 7px;
      right: 12px;
    }
    .flash-title {
      padding: 0 0 6px;
      font-size: 16px;
    }
    .flash-content {
      font-size: 16px;
    }
  }
}
</style>
